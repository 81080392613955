import styles from './Home.module.css'

const Home = () => {
  return (
    <div className={styles.home}>
       <p>Em contrução</p>
    </div>
  )
}

export default Home